import { Entity } from '../entities/entities.model';
import { ProgramPhase, ProgramType, ProgramUtilization, Sector } from '../entities/program/program.model';
import { TacticPhase, TacticType } from '../entities/tactic/tactic.model';
import { CostType } from '../entities/cost/cost.model';
import { ColumnCollection, DynamicColumn, SortStrategy } from '../table/table.model';
import { ExternalIdType } from '../entities/external-id/external-id.model';
import { MeasurementType } from '../entities/measurement/measurement.model';
import { InvestmentType } from '../entities/investment/investment.model';
import { Brand } from '../entities/brand/brand.model';
import { OrganizationSettings, PercentageOfSpendCompleteColumn } from '../../../../../api/src/organization/organization.settings';
import { ProgramSelect } from '../../../../../api/src/program/utils/query.utils';
import { TacticSelect } from '../../../../../api/src/tactic/utils/query.utils';
import { AggregateFunction, Condition } from '../../../../../api/src/_core/models/math-operations';
import { BrandInitiative } from '../../../../../api/src/brand-initiative/brand-initiative.entity';
import { MediaType } from '../../../../../api/src/base-tactic-type/base-tactic-type.entity';
import { MeasurementGroup } from '../entities/measurement-group/measurement-group.model';
import { Vendor } from '../../../../../api/src/vendor/vendor.entity';
import { VendorType } from '../../../../../api/src/vendor-type/vendor-type.entity';
import { PublicAgency } from '../../../../../api/src/agency/agency.entity';
import { PublicAuthenticationStrategy } from '../../../../../api/src/authentication-strategy/authentication-strategy.entity';
import { PublicBudgetPeriod } from '../../../../../api/src/budget-period/budget-period.entity';
import { PublicCalendarLayer } from '../../../../../api/src/calendar-layer/calendar-layer.entity';
import { UserSettings } from '../../../../../api/src/user/models/user.settings';
import { PublicTacticAgency } from '../../../../../api/src/tactic-agency/tactic-agency.entity';

export interface GlobalState {
	header: HeaderSettings;
	settings: GlobalSettings;
	adminMode: boolean;
}

export const initialGlobalState: GlobalState = {
	header: {
		visible: true
	},
	settings: undefined,
	adminMode: false
};

/**
 * Global Settings Model
 * This model reflects the organization settings that come from the API
 * These settings contain the default options for most entities as well as any
 * org-level configuration.
 */
export interface GlobalSettings {
	name: string;
	settings: OrganizationSettings;
	userSettings: UserSettings;
	slug: string;
	authenticationStrategies: PublicAuthenticationStrategy[];
	signupMethod: string;
	logo: string;
	retailers: Retailer[];
	agencies: PublicAgency[];
	categories: Category[];
	budgetPeriods: PublicBudgetPeriod[];
	brands: Brand[];
	vendor: Vendor[];
	vendorTypes: VendorType[];
	brandInitiatives: BrandInitiative[];
	externalIdTypes: ExternalIdType[];
	measurementGroups: MeasurementGroup[];
	measurementTypes: MeasurementType[];
	programSectors: Sector[];
	programPhases: ProgramPhase[];
	programTypes: ProgramType[];
	programUtilizations: ProgramUtilization[];
	fundingSources: FundingSource[];
	fundingTypes: FundingType[];
	tacticTypes: TacticType[];
	tacticTypeGroupByCategory?: OptionGroup<TacticType>[];
	tacticPhases: TacticPhase[];
	costTypes: CostType[];
	investmentTypes: InvestmentType[];
	mediaTypes: MediaType;
	calendarLayers: PublicCalendarLayer[];
	tacticAgencies: PublicTacticAgency[];
}

/**
 * Track what page section we are on at any time.
 */
export type AppSection = 'dashboard' | 'activation' | 'planning' | 'media-plan';

/**
 * Settings that can be applied to the top header.
 */
export interface HeaderSettings {
	visible: boolean;
}

/**
 * TODO: Document this
 */
export interface Category extends Entity {}

/**
 * Retailer Model
 * Retailers are an entity that you can attach to plans and programs.
 */
export interface Retailer extends Entity {}

/**
 * Funding Source model
 * Funding Sources are business units that are providing funding for programs.
 * Sources can have funding types nested underneath them.
 */
export interface FundingSource extends Entity {
	fundingTypes: FundingType[];
}

/**
 * Funding Type model
 * Funding types help describe the type of funding coming from a
 * source that may have different types of funding.
 */
export interface FundingType extends Entity {}

/**
 * Brand Allocation model
 * Budget allocations can have their values split by brand.  Brand allocations describe
 * how each brand is attributed to the share of the budget allocation total.
 */
export interface BrandAllocations {
	brand?: Brand;
	brandId?: string;
	split: string;
}

/**
 * Budget caches are cached data of all of the budget totals for programs and their nested entities.
 */
export interface BudgetCache {
	amountEstimated: number;
	amountPlanned: number;
	amountActual: number;
	spendEstimated: number;
	spendActual: number;
}

/**
 * A generic grouped option container.  Used for nested select compnonents.
 */
export interface OptionGroup<T> {
	name: string;
	items: T[];
}

/**
 * Entities can have 'modes' that describe whether the UI should allow editing or creating those entities.
 */
export type EntityContainerMode = 'create' | 'edit' | 'snapshot';

/**
 * This list helps desribe the major entities in the system for typing.
 */
export type Entities =
	| 'budgetPeriod'
	| 'plan'
	| 'program'
	| 'tactic'
	| 'invoice'
	| 'retailer'
	| 'brand'
	| 'milestones'
	| 'budget-allocation'
	| 'cost'
	| 'comment';

/**
 * This object helps wrap debounced changes so that you can pipe through
 * debounce methods before subscribing and doing something with the value.
 */
export interface DebouncedValueChange {
	key: string;
	value: string;
	debounceTime: number;
}

export interface FormPropertyUpdateObject {
	fieldName: string;
	data: any;
	updatedEntity?: any; // Optionally pass through an updated entity from the API if we already got a response.
}

/**
 * The Program section represents the tabbed sections of the program layout.
 */
export interface TabSection {
	label: string;
	slug: string;
}

/**
 * Describes the website paths to get to certain entity pages.
 */
export const EntityLinkPaths = {
	plan: '/plan/:id',
	planProgram: '/plan/:parentId/program/:id',
	program: '/program/:id',
	tactic: '/program/:parentId/tactic/:id',

	tacticgroup: '/media-plan/:parentId/scenarios/:id',
	tacticgrouptactic: '/media-plan/:parentId/scenarios/:tacticGroupId/tactic/:tacticId',
	invoice: '/program/:parentId/tactic/:id/invoices',
	budgetPeriod: '/budget-period/:id',
	brandinitiative: '/initiative/:id',
	vendor: '/vendor/:id'
};

export function getChildEntity(type: Entities): Entities {
	switch (type?.toLowerCase()) {
		case 'plan':
			return 'program';
		case 'program':
			return 'tactic';
		case 'tactic':
			return 'invoice';
		default:
			return undefined;
	}
}

/**
 * Columns that apply to all entities in table views.
 */
export const OverallColumnCollection: ColumnCollection = {
	id: 'overall',
	name: 'Overall',
	items: [
		{
			id: 'id',
			name: 'ID',
			path: 'id',
			type: 'field',
			entityTypes: ['plan', 'program', 'tactic', 'invoice'],
			category: 'Overall',
			extra: {
				width: 300
			}
		},
		{
			id: 'name',
			name: 'Name',
			path: 'name',
			type: 'field',
			entityTypes: ['plan', 'program', 'tactic', 'invoice'],
			category: 'Overall',
			hideFromMenu: true,
			extra: {
				width: 300
			}
		},
		{
			id: 'start',
			name: 'Start Date',
			path: 'start',
			type: 'date',
			category: 'Overall',
			entityTypes: ['program', 'tactic'],
			editable: {
				type: 'date',
				enabled: true,
				uneditableReason: 'Start Date is not editable.'
			},
			extra: {
				width: 120
			},
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'activation'
			}
		},
		{
			id: 'end',
			name: 'End Date',
			path: 'end',
			type: 'date',
			category: 'Overall',
			entityTypes: ['program', 'tactic'],
			editable: {
				type: 'date',
				enabled: true,
				uneditableReason: 'End Date is not editable.'
			},
			extra: {
				width: 120
			},
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'activation'
			}
		},
		{
			id: 'created',
			name: 'Date Created',
			path: 'created',
			type: 'date',
			category: 'Overall',
			entityTypes: ['plan', 'program', 'tactic', 'invoice']
		},
		/*
		{
			id: 'planned-allocated-budget',
			name: 'Amount Planned',
			path: 'budgetCache.amountPlanned',
			exportPath: 'budgetCache.amountPlanned',
			type: 'budgetCacheValue',
			category: 'Budgets',
			entityTypes: ['program'],
			dependencies: [ProgramSelect.BudgetCache],
			extra: {},
			editable: {
				enabled: false,
				uneditableReason: 'Amount Planned must be edited in the Plan section.'
			}
		}, */
		{
			id: 'actual-program-budget',
			name: 'Actual Program Budget',
			path: 'budgetCache.amountActual',
			exportPath: 'budgetCache.amountActual',
			type: 'budgetCacheValue',
			category: 'Budgets',
			entityTypes: ['program'],
			dependencies: [ProgramSelect.BudgetCache],
			extra: {},
			/*editable: {
				type: 'quick-edit-dialog',
				enabled: true,
				dismissWithUpdatedEntity: true,
				modalSize: 'lg'
			},*/
			editable: {
				enabled: false,
				uneditableReason: 'Actual Program Budget cannot be edited.'
			},
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'activation'
			},
			hideFromMenu: true
		},
		{
			id: 'estimated-spend',
			name: 'Estimated Spend',
			path: 'budgetCache.spendEstimated',
			exportPath: 'budgetCache.spendEstimated',
			type: 'budgetCacheValue',
			category: 'Budgets',
			entityTypes: ['tactic'],
			dependencies: [ProgramSelect.BudgetCache],
			extra: {
				showDifferenceWith: 'amountActual',
				invertDifference: true
			},
			editable: {
				type: 'quick-edit-dialog',
				enabled: true,
				dismissWithUpdatedEntity: true,
				modalSize: 'lg'
			},
			hideFromMenu: true
		},
		{
			id: 'actual-spend',
			name: 'Actual Spend',
			path: 'budgetCache.spendActual',
			exportPath: 'budgetCache.spendActual',
			type: 'budgetCacheValue',
			category: 'Budgets',
			entityTypes: ['tactic', 'invoice'],
			dependencies: [ProgramSelect.BudgetCache],
			extra: {
				showDifferenceWith: 'spendEstimated',
				invertDifference: true
			},
			editable: {
				type: 'quick-edit-dialog',
				enabled: true,
				dismissWithUpdatedEntity: true,
				modalSize: 'lg',
				rowTypeOverrides: [
					{
						rowType: 'invoice',
						type: 'text'
					}
				]
			},
			hideFromMenu: true
		},
		// {
		// 	name: 'Plan Budget',
		// 	path: 'amountPlanned',
		// 	type: 'budgetCacheValue',
		// 	extra: {
		// 		showDifferenceWith: 'spendEstimated',
		// 		invertDifference: true
		// 	}
		// },
		{
			id: 'brands',
			name: 'Brand(s)',
			path: 'brands',
			exportPath: 'brands.name',
			dependencies: [ProgramSelect.Brands],
			type: 'badges',
			category: 'Overall',
			entityTypes: ['plan', 'program', 'tactic'],
			editable: {
				enabled: false,
				uneditableReason: 'Click into this entity to edit brands.'
			},
			extra: {
				width: 300
			},
			hideFromMenu: true,
			sortable: false
		},
		{
			id: 'tags',
			name: 'Tags',
			path: 'tags',
			exportPath: 'tags.name',
			type: 'badges',
			category: 'Overall',
			dependencies: [ProgramSelect.Tags],
			entityTypes: ['plan', 'program', 'tactic'],
			editable: {
				type: 'multi-select',
				filterId: '${type}-tags',
				enabled: true,
				uneditableReason: 'Tags are not editable.'
			},
			sortable: false,
			hideFromMenu: true
		},
		{
			id: 'latest-note',
			name: 'Latest Note', // Can use merge tags
			path: 'notes', // Should be unique per type, use merge tags.
			type: 'pluckFromArray',
			category: 'Overall',
			exportPath: 'notes', // Has to be the path to the items.
			entityTypes: ['program'],
			dependencies: [ProgramSelect.Notes],
			editable: {
				type: 'notes',
				enabled: true,
				uneditableReason: 'Notes are not editable.'
			},
			extra: {
				tooltip: true,
				stripHtml: true
			},
			aggregate: {
				path: 'body', // Property to pluck from
				function: AggregateFunction.First // 'join', 'count', 'sum', 'average', 'first'
			},
			sortable: false,
			hideFromMenu: true
		},
		{
			id: 'created-by',
			name: 'Created By',
			path: 'author',
			exportPath: 'author.name',
			type: 'user',
			category: 'Overall',
			entityTypes: ['plan', 'program', 'tactic', 'invoice'],
			dependencies: [ProgramSelect.Author],
			sortable: false
		},
		{
			id: 'date-range',
			name: 'Date Range',
			path: 'date-range', // Doesn't matter for this one
			type: 'date-range',
			category: 'Overall',
			entityTypes: ['plan', 'program', 'tactic'],
			dependencies: [],
			sortable: false,
			hideFromMenu: true
		},
		// This object is only used for the calendar to show the merged budget number.
		{
			id: 'budget',
			name: 'Budget',
			path: 'budget',
			exportPath: 'budget',
			type: 'currency',
			category: 'Budgets',
			entityTypes: ['plan', 'program', 'tactic', 'invoice'],
			dependencies: [ProgramSelect.BudgetCache],
			hideFromMenu: true,
			extra: {
				showDifferenceWith: 'spendEstimated',
				invertDifference: true
			}
		},
		{
			id: 'date-range-aggregated',
			name: 'Date Range (Aggregated)',
			path: 'dateRange',
			type: 'aggregated-date-range',
			category: 'Overall',
			entityTypes: ['plan', 'program', 'tactic'],
			hideFromMenu: true,
			sortable: false
		},
		{
			id: 'retailers',
			name: 'Retailers',
			path: 'retailers',
			type: 'badges',
			category: 'Overall',
			entityTypes: ['plan', 'program', 'tactic'],
			sortable: false,
			hideFromMenu: true,
			editable: {
				type: 'uneditable',
				uneditableReason: 'Retailers are not editable once set.'
			}
		},
		{
			id: 'counts',
			name: 'Counts',
			path: 'count',
			type: 'childrenCount',
			category: 'Overall',
			entityTypes: ['program', 'tactic'],
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'activation'
			},
			sortable: false
		},
		{
			id: 'all-external-ids',
			name: 'All External Ids',
			path: 'externalIds',
			exportPath: 'externalIds',
			type: 'field',
			category: 'External Ids',
			entityTypes: ['tactic', 'program', 'invoice'],
			sortStrategy: SortStrategy.ASC,
			dependencies: [TacticSelect.ExternalIds],
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'activation'
			}
		},
		{
			id: 'all-measurements',
			name: 'All Measurements',
			path: 'measurements',
			exportPath: 'measurements',
			type: 'field',
			category: 'Measurement',
			entityTypes: ['tactic'],
			sortStrategy: SortStrategy.ASC,
			dependencies: [TacticSelect.Measurements],
			extra: {
				alternativeName: 'All Measurements'
			},
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'activation'
			}
		},
		{
			id: 'create-child',
			name: 'Create Child Entity',
			path: 'createChild',
			type: 'button',
			category: 'Overall',
			entityTypes: ['program', 'tactic'],
			editable: {
				enabled: false,
				hideSnackBar: true
			},
			button: {
				action: 'createChild',
				type: 'mat-mini-fab',
				icon: 'uil-plus',
				size: 'extra-small',
				color: 'tertiary',
				tooltip: 'Create ##CHILD_ENTITY##'
			},
			extra: {
				width: 180,
				hideHeaderLabel: false,
				useChildEntity: true
			},
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'activation'
			},
			sortable: false
		},
		{
			id: 'plan-allocated-budget',
			name: 'Plan Allocated Budget',
			path: 'budgetCacheValue.amountEstimated',
			exportPath: 'budgetCacheValue.amountEstimated',
			type: 'budgetCacheValue',
			category: 'Budgets',
			entityTypes: ['plan', 'program'],
			dependencies: [ProgramSelect.BudgetAllocations],
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'planning'
			},
			hideFromMenu: true
		},
		{
			id: 'plan-planned-program-budget',
			name: 'Planned Program Budget',
			path: 'budgetCache.amountPlanned',
			exportPath: 'budgetCache.amountPlanned',
			type: 'budgetCacheValue',
			category: 'Budgets',
			entityTypes: ['plan', 'program'],
			dependencies: [ProgramSelect.BudgetCache],
			extra: {},
			/* visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'planning'
			}*/ hideFromMenu: true
		},
		{
			id: 'plan-planned-program-investment',
			name: 'Planned Program Investment',
			path: 'investments',
			exportPath: 'investments',
			type: 'pluckFromArray',
			category: 'Budgets',
			entityTypes: ['plan', 'program'],
			dependencies: [],
			extra: {
				mask: 'currency',
				aggregateElementsByParentKey: 'Plan'
			},
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'planning'
			},
			aggregate: {
				path: 'amount',
				function: AggregateFunction.Sum
			},
			hideFromMenu: true,
			sortable: false
		},
		{
			id: 'funding-source',
			name: 'Funding Source',
			path: 'fundingSource',
			type: 'custom',
			extra: {
				customMapping: 'fundingSources',
				arrayPath: 'budgetAllocations',
				mapPath: 'fundingSource.name'
			},
			entityTypes: ['program', 'plan'],
			dependencies: [ProgramSelect.BudgetAllocations],
			category: 'Budgets',
			hideFromMenu: true,
			sortable: false
		},
		{
			id: 'budget-event-id',
			name: 'Event ID',
			path: 'budgetEventId',
			type: 'field',
			entityTypes: ['program'],
			dependencies: [ProgramSelect.BudgetAllocations],
			category: 'Budgets',
			hideFromMenu: true,
			sortable: false
		},
		{
			id: 'percentage-of-budget-spend-complete',
			name: '% of Budget Spend Complete',
			path: 'percentBudgetSpendComplete',
			type: 'percentSpentComplete',
			entityTypes: ['program'],
			category: 'Budgets',
			visibilityCondition: {
				path: 'settings.percentageOfSpendCompleteColumn',
				condition: Condition.NEQ,
				value: 'off' as PercentageOfSpendCompleteColumn
			},
			hideFromMenu: true,
			sortable: false
		},
		{
			id: 'percentage-of-tactic-spend-complete',
			name: '% of Tactic Spend Complete',
			path: 'percentTacticSpendComplete',
			type: 'percentSpentComplete',
			entityTypes: ['tactic'],
			category: 'Tactic',
			visibilityCondition: {
				path: 'settings.percentageOfSpendCompleteColumn',
				condition: Condition.NEQ,
				value: 'off' as PercentageOfSpendCompleteColumn
			},
			hideFromMenu: true,
			sortable: false
		},
		{
			id: 'funding-type',
			name: 'Funding Type',
			path: 'fundingType',
			type: 'custom',
			extra: {
				customMapping: 'fundingTypes',
				arrayPath: 'budgetAllocations',
				mapPath: 'fundingType.name'
			},
			entityTypes: ['program', 'plan'],
			dependencies: [ProgramSelect.BudgetAllocations],
			category: 'Budgets',
			hideFromMenu: true,
			sortable: false
		},
		{
			id: 'allocated-budget',
			name: 'Allocated Budget',
			path: 'budgetCache.amountActual',
			exportPath: 'budgetCache.amountActual',
			type: 'budgetCacheValue',
			category: 'Budgets',
			entityTypes: ['program'],
			dependencies: [ProgramSelect.BudgetCache],
			editable: {
				type: 'quick-edit-dialog',
				enabled: true,
				dismissWithUpdatedEntity: true,
				modalSize: 'lg'
			},
			hideFromMenu: true
		}
	]
};

export const DynamicColumnCollection: ColumnCollection = {
	id: 'dynamic',
	name: 'Dynamic Columns',
	items: []
};

export const DynamicColumns: DynamicColumn[] = [
	{
		id: 'external-ids',
		settingsKey: 'externalIdTypes',
		collectionName: 'dynamic',
		column: {
			id: 'externalIds-$id', // Should be unique per type, use merge tags.
			name: '$name', // Can use merge tags
			path: 'externalIds-$id',
			type: 'pluckFromArray',
			category: 'External Ids',
			exportPath: 'externalIds', // Has to be the path to the items.
			entityTypes: ['plan', 'program', 'tactic', 'invoice'],
			dependencies: [ProgramSelect.ExternalIds],
			filter: {
				path: 'externalIdType.id', // Path to filter property
				condition: Condition.EQ,
				value: undefined // Value to filter for
			},
			aggregate: {
				path: 'value',
				function: AggregateFunction.Join
			},
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'activation'
			},
			extra: {},
			editable: {
				enabled: true,
				type: 'quick-edit-dialog',
				idAlias: 'external-ids',
				dismissWithUpdatedEntity: true
			}
		}
	},
	{
		id: 'measurements',
		settingsKey: 'measurementTypes',
		collectionName: 'dynamic',
		maskTypePath: 'unit',
		column: {
			id: 'measurements-$id', // Should be unique per type, use merge tags.
			name: '$name', // Can use merge tags
			path: 'measurements-$id',
			type: 'pluckFromArray',
			category: 'Measurement',
			entityTypes: ['tactic'],
			exportPath: 'measurements', // Has to be the path to the items.
			dependencies: [TacticSelect.Measurements],
			filter: {
				path: 'measurementType.id', // Path to filter property
				condition: Condition.EQ,
				value: undefined // Value to filter for
			},
			aggregate: {
				path: 'value',
				function: AggregateFunction.First
			},
			extra: {
				width: 100
			},
			editable: {
				enabled: true,
				type: 'quick-edit-dialog',
				idAlias: 'measurements',
				modalSize: 'lg',
				dismissWithUpdatedEntity: true
			}
		}
	}
];

export enum UserRole {
	SuperAdmin = 'super-admin',
	Admin = 'admin',
	Analyst = 'analyst',
	User = 'user',
	Guest = 'guest',
	Sales = 'sales',
	OutsideAgency = 'outside-agency'
}

export enum PermissionLevel {
	Read = 'read',
	Write = 'write',
	Maintain = 'maintain'
}

export enum PermissionType {
	Retailer = 'retailer',
	Agency = 'agency'
}

export const UserRoleMinPermissionLevelMap = {
	[UserRole.SuperAdmin]: PermissionLevel.Maintain,
	[UserRole.Admin]: PermissionLevel.Maintain,
	[UserRole.User]: PermissionLevel.Maintain,
	[UserRole.Analyst]: PermissionLevel.Read,
	[UserRole.Sales]: PermissionLevel.Read,
	[UserRole.Guest]: PermissionLevel.Read,
	[UserRole.OutsideAgency]: PermissionLevel.Maintain
};

/**
 * Defines the different types of values that we can handle.
 */
export type ValueType =
	| 'field'
	| 'date'
	| 'percentage'
	| 'currency'
	| 'entityName'
	| 'entityNames'
	| 'budgetCacheValue'
	| 'badge'
	| 'badges'
	| 'user'
	| 'users'
	| 'pluckFromArray'
	| 'brandStrategy'
	| 'date-range'
	| 'aggregated-date-range'
	| 'pluckFromChildrenArray'
	| 'childrenCount'
	| 'createChild'
	| 'button'
	| 'number'
	| 'custom'
	| 'percentSpentComplete';

/**
 * A simple group primitive to use with other content
 */
export interface Group<T> {
	id: string;
	items: T[];
	name?: string;
	entity?: Entity;

	// Allow other data to come along with the group
	[key: string]: any;
}

/**
 * API Map model
 * This model describes a map of domain selectors that can point to a specific api endpoint.
 * It's used to determine what API to use on production if we want to support multiple tenants on one instance.
 */
export interface ApiMap {
	domain: string;
	endpoint: string;
	organizationId: string;
}

/**
 * Tracks the progress of a file upload
 */
export interface FileProgressData {
	progress: number;
	error: string;
}

/**
 * Admin Dashboard Client Dropdowns
 */

export interface AdminDashboardClientDropdowns {
	readonly retailers: { [id: string]: number };
	readonly programPhases: { [id: string]: number };
	readonly programSectors: { [id: string]: number };
	readonly programBrands: { [id: string]: number };
	readonly programProducts: { [id: string]: number };
	readonly tacticTypes: { [id: string]: number };
	readonly tacticPhases: { [id: string]: number };
	readonly tacticVendors: { [id: string]: number };
}

/**
 * Admin Compliance Tracker
 */

// DTO

export interface AdminComplianceTrackerDto {
	readonly budgetPeriodId: string;
	readonly brandIds: string[];
	readonly retailerIds: string[];
	readonly programPhaseIds: string[];
	readonly programTypeIds: string[];
}

// API Response

export interface AdminComplianceTrackerResponseTacticMeasurement {
	readonly id: string;
	readonly benchmark?: number;
	readonly value?: number;
}

export interface AdminComplianceTrackerResponseTactic {
	readonly id: string;
	readonly name: string;
	readonly vendorCount: number;
	readonly totalCost: number;
	readonly hasTacticPhase: boolean;
	readonly measurements: AdminComplianceTrackerResponseTacticMeasurement[];
}

export interface AdminComplianceTrackerResponseProgram {
	readonly id: string;
	readonly name: string;
	readonly keyLearnings?: string;
	readonly recommendations?: string;
	readonly hasProgramPhase: boolean;
	readonly hasObjective: boolean;
	readonly totalBudgetAllocation: number;
	readonly productCount: number;
	readonly fileCreativeCount: number;
	readonly tactics: AdminComplianceTrackerResponseTactic[];
	readonly updated?: Date;
	readonly hasProgramType: boolean;
}

export interface AdminComplianceTrackerResponse {
	readonly id: string;
	readonly name: string;
	readonly programs: AdminComplianceTrackerResponseProgram[];
}
